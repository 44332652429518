import React, { useState } from 'react';
import './Greetings.css';
import unitel_logo from './assets/unitel_logo.png';
const Greetings = ({ onClose }) => {
    return (
      <div className="greetings-container">
        
        <button className="greetings-back-button"  onClick={onClose}>&lt;</button>
        <div className="unitel-logo">
          <img src={unitel_logo} alt="Unitel" />
        </div>
        <div className="greetings-box">
          <p className="title">Мэндчилгээ</p>
          <p className='greeting'>Интермед эмнэлгийн эмч, сувилагч, ажилтан та бүгдийн мэргэжлийн ур чадвар, хичээл зүтгэл, сайхан сэтгэл мянга мянган хүний амьдралд маш том өөрчлөлт авчирч, эрүүл жаргалтай амьдрах үндэс суурь болдог билээ. Бидний хүсэн хүлээдэг баатрууд байдагт баярлалаа. 
          <br></br>Бахархам амжилтаар дүүрэн 10 жилийн ойн баярын мэнд хүргэе!</p>
          <br></br>
          <i>    Хүндэтгэсэн,
          <br></br>
          Юнител группийн хамт олон</i>
        </div>
      </div>
    );
};

export default Greetings;