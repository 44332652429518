import React,{ useState } from 'react';
import './App.css';
import Login from './Login';

import Greetings from './Greetings';

import Lottery from './Lottery';
function App() {
  const [greetingsDisplay, setGreetingsDisplay] = useState(true);
  const [loginDisplay, setLoginDisplay] = useState(false);
  const [lotteryDisplay, setLotteryDisplay] = useState(false);

  const handleGreetingsClose = () => {
    setGreetingsDisplay(false);
    setLoginDisplay(true);
  };
  const handleLottery = () => {
    setGreetingsDisplay(false);
    setLoginDisplay(false);
    setLotteryDisplay(true);
  }

  return (
    <div>
      {greetingsDisplay && <Greetings onClose={handleGreetingsClose} />}
      {!greetingsDisplay && loginDisplay && <Login onSuccess={handleLottery}/>}
      {!greetingsDisplay && !loginDisplay && lotteryDisplay && <Lottery />}
      {/* <Lottery /> */}
    </div>
  );
}

export default App;
