import { useState } from 'react';

import RoulettePro from 'react-roulette-pro';
import 'react-roulette-pro/dist/index.css';

import {Button ,Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import { Alert, Snackbar } from '@mui/material';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import unitel_logo from './assets/unitel_logo.png';
import galaxys24 from './assets/galaxy_s24.jpg';
import iphone15 from './assets/iphone_15.jpg';
import monosvoucher from './assets/monos_voucher.jpg';
import parkvoucher from './assets/park_voucher.jpg';
import skyresortvoucher from './assets/sky_resort_voucher.jpg';
import soyolvoucher from './assets/soyol_voucher.jpg';
import urgoovoucher from './assets/urgoo_voucher.jpg';
import suravoucher from './assets/sura_voucher.jpg';
import voganuzegchiinalh from './assets/vogan_uzegchiin_alh.jpg';
import zamchdiinalh from './assets/zamchdin_alh.jpg';
import zutguuriinmaket from './assets/zutguuriin_maket.jpg';
import Gift from './Gift';
import gb_5_img from './assets/5_gb.jpg';
import gb_10_img from './assets/10_gb.jpg';
import gb_30_img from './assets/30_gb.jpg';
import gb_50_img from './assets/50_gb.jpg';
import gb_75_img from './assets/75_gb.jpg';
import upoint from './assets/u-point.png';
import './Lottery.css'; 


const prizes = [
  {
    image: upoint,
    text: '10000 Upoint',
  },
  {
    image: gb_5_img,
    text: '5GB Дата /2 хоногийн хугацаатай /',
  },
  {
    image: gb_10_img,
    text: '10GB Дата /5 хоногийн хугацаатай /',
  },
  {
    image: gb_30_img,
    text: '30GB Дата /10 хоногийн хугацаатай /',
  },
  {
    image: gb_50_img,
    text: '50GB Дата /10 хоногийн хугацаатай /',
  },
  {
    image: gb_75_img,
    text: '75GB Дата /10 хоногийн хугацаатай /',
  }
];

const prizeIndexMap = {
  1000: 0,
  1001: 1,
  1002: 2,
  1003: 3,
  1004: 4,
  1005: 5
};

const reproductionArray = (array = [], length = 0) => [
  ...Array(length)
    .fill('_')
    .map(() => array[Math.floor(Math.random() * array.length)]),
];

const reproducedPrizeList = [
  ...prizes,
  ...reproductionArray(prizes, prizes.length * 3),
  ...prizes,
  ...reproductionArray(prizes, prizes.length),
];

const generateId = () =>
  `${Date.now().toString(36)}-${Math.random().toString(36).substring(2)}`;

const prizeList = reproducedPrizeList.map((prize) => ({
  ...prize,
  id: typeof crypto.randomUUID === 'function' ? crypto.randomUUID() : generateId(),
}));

const Lottery = () => {
  const [start, setStart] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [winPrizeIndex, setWinPrizeIndex] = useState(11);
  const [seqId, setSeqId] = useState(0);
  const [showGift, setShowGift] = useState(false); 

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');

  const [loading, setLoading] = useState(false);
  const [buttonClick, setButtonClick] = useState(false);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const prizeIndex = prizes.length * 4 + winPrizeIndex;

  const handleDialogOpen = () => {
    setDialogOpen(true);
};

const handleDialogClose = () => {
  setDialogOpen(false);
};


  const handleStart = () => {
    setLoading(true);
    setButtonClick(true);
    fetch('https://campaign.unitel.mn/spin/get_gift', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      setLoading(false);
      if (data.status === "success") {
        setWinPrizeIndex(prizeIndexMap[Number(data.giftId)]);
        setStart((prevState) => !prevState);
        setSeqId(Number(data.seqId) );
      } else {
        setAlertMessage('Танд хүрд эргүүлэх эрх байхгүй байна.');
        setAlertSeverity('info');
        setAlertOpen(true);
        setButtonClick(false); //check
        console.error('Error:', data);
      }
      
    })
    .catch((error) => {
        setLoading(false);
        
        setButtonClick(false);
        console.error('Error:', error);
    });


   
  };

  const handlePrizeDefined = () => {
    console.log('🥳 Prize defined! 🥳');

    setShowGift(true);
    setButtonClick(false);
    fetch(`https://campaign.unitel.mn/spin/claim_gift?seqId=${seqId}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log('Gift claimed successfully:', data);
    })
    .catch((error) => {
      console.error('Error claiming gift:', error);
    });
  };
  const handleBack = () => {
    // history.goBack();
  };

  const handleHelp = () => {
    alert('Help information goes here.');
  };


  return (
    <>
     {loading && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999
        }}>
          <CircularProgress />
        </div>
      )}
    {showGift ? (
      <Gift winprizeimage={prizes[winPrizeIndex].image} winprizename={prizes[winPrizeIndex].text}/>
    ) : (
      <>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
        <div className="lottery-header">
          <button className="back-button" hidden onClick={handleBack}>&lt;</button>
          <button className="lottery-help-button" hidden onClick={handleHelp}>?</button>
        </div>
        <img className='unitel-logo' src={unitel_logo} alt="Unitel" />
        <RoulettePro
          prizes={prizeList}
          prizeIndex={prizeIndex}
          spinningTime={3}
          start={start}
          onPrizeDefined={handlePrizeDefined}
          options={{ stopInCenter: true }}
        />
        <div className="button-container">
          <button className='button-green' onClick={handleStart} disabled={buttonClick}>Хүрд эргүүлэх </button>
          <button className='button-gradient' onClick={handleDialogOpen}>  <img src="https://img.icons8.com/ios-filled/50/ffffff/gift.png" alt="Gift Icon" />Бэлэг харах</button>
        </div>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Хүрдний бэлэгнүүд:</DialogTitle>
          <DialogContent>
            <List>
              {prizes.map((prize, index) => (
                <>
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar src={prize.image} />
                    </ListItemAvatar>
                    <ListItemText primary={prize.text} />
                  </ListItem>
                  {index < prizes.length - 1 && <Divider />}
                </>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )}
  </>
  );
};

export default Lottery;