import React, { useState } from 'react';
import './Gift.css';

import { Box, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import unitel_logo from './assets/unitel_logo.png';
import iphone15 from './assets/iphone_15.jpg';
const Gift = ({ winprizeimage, winprizename }) => {
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const handleButtonClick = () => {
        setIsButtonClicked(true);
      };

    return (
      <div className="gift-container">
        
        {/* <button className="gift-back-button"  onClick={onClose}>&lt;</button> */}
        
        <div className="gift-box">
        <div className="gift-unitel-logo">
          <img src={unitel_logo} alt="Unitel" />
        </div>
        {!isButtonClicked ? 
            <div className='celebration-container'>
            <p className="title1">БАЯР ХҮРГЭЕ</p>
            <p className="title2">Танд {winprizename}</p>
            <p className="title3">бэлэглэж байна.</p>
            <img src={winprizeimage} width={200} alt="iPhone 15" className="gift-image" />
            <p className='gift-message'>Хамтдаа түрүүлж алхдаг та бүхэн сайхан баярлаарай.</p>
        </div>
        :
        // <div className='location-container'>
        //   <p className="location-p">УБТЗ Мэргэжлийн баяр Хонхорт байрлах Юнител асраас ирж бэлгээ аваарай.</p>
          
        //   <div style={{ display: 'flex', justifyContent:'center', }}>
        //     <PhoneIcon sx={{ color: '#39b54a', marginRight: '10px', fontSize: '1.5em' }} />
        //     <Typography variant="body1" component="p">
        //       8611-0631, 8911-8303
        //     </Typography>
        //   </div>
        // </div>
        <div></div>
        }
        
          
        </div>
        {/* { !isButtonClicked ?  <button  onClick={handleButtonClick} className='button-green'>БЭЛЭГ АВАХ БАЙРШИЛ</button> : <></>} */}
      </div>
    );
};

export default Gift;