import React, { useState,useEffect } from 'react';

import unitel_logo from './assets/unitel_logo.png';
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';

import CircularProgress from '@mui/material/CircularProgress';
import './Login.css';

const Login = ({ onSuccess }) => {
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [otp, setOtp] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [step, setStep] = useState('phone'); // 'phone' or 'otp'
    const [token, setToken] = useState('');

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('Та гар утасны дугаараа оруулна уу');

    const handleLogin = () => {
        setLoading(true);
        if (step === 'phone') {
           fetch(`https://campaign.unitel.mn/otp/generate?mobileNumber=${phoneNumber}`, {
            method: 'GET',
        })
        .then(response => {
            setLoading(false);
            if(response.status === 200){
                
                return response.text();
            }else if(response.status === 401){
                return "burtgelgui";
            }else{
                return "error";
            }
        })
        .then(text => {
            if(text === "error"){   
                setMessage("Таны оруулсан утасны дугаар буруу байна.")
            }else if(text === "burtgelgui"){
                setMessage("Таны дугаар бүртгэлгүй байна.")
            }else{
                setSecretKey(text);
                setStep('otp');
                setMessage('Та OTP кодоо оруулна уу');
            }
            
        })
        .catch(error => {
            setLoading(false);
            console.error('Error sending OTP:', error);
        });
        } else {
            fetch(`https://campaign.unitel.mn/otp/validate?secretKey=${secretKey}&otp=${otp}&mobileNumber=${phoneNumber}`, {
                method: 'GET',
            })
            .then(response => {
                console.log("response status", response.status);
                setLoading(false);
                if(response.status === 200){
                    return response.text();
                }else{
                    setMessage("Таны оруулсан код буруу байна.")
                    throw new Error('OTP validation failed');
                }
            })
            .then(text => {
                setToken(text);
                localStorage.setItem('token', text); 
                onSuccess();
            })
            .catch(error => {
                setLoading(false);
                console.error('Error verifying OTP:', error);
            });
        }
    };

    return (
        <div className="login-container">
            {loading && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999
        }}>
          <CircularProgress />
        </div>
      )}
            <div className="login-box">
                <div className="login-unitel-logo">
                    <img src={unitel_logo} alt="Unitel" />
                </div>
                <h2>{message}</h2>
                {step === 'phone' ? (
                    <div>
                        <NumberInput
                            id='phone-number'
                            placeholder="XXXX-XXXX"
                            onInputChange={(val) => setPhoneNumber(Number(val.target.value))}
                        />
                    </div>
                    
                ) : (
                    <input
                    type='number'
                    id='otp'
                    placeholder={'OTP'}
                    onChange={(val) => setOtp(val.target.value)}
                />
                    
                )}
            </div>
            <button className='login-box-button' disabled={loading} onClick={handleLogin}>
                {step === 'phone' ? 'НЭВТРЭХ' : 'БАТАЛГААЖУУЛАХ'}
            </button>
        </div>
    );
};

export default Login;